<template>
    <b-row>
        <b-col cols="12" class="mb-32">
            <page-title />
        </b-col>
        <div class="magazine-container">
            <b-card>
                <div class="breadcrumb-custom">
                    <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
                </div>
                <b-form>
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-xl-6"> <b-form-group id="input-group-2" label="ชื่อ "
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="firstname" placeholder="กรอกชื่อ"
                                    required></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"> <b-form-group id="input-group-2" label="นามสกุล "
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="lastname" placeholder="กรอกนามสกุล"
                                    required></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"> <b-form-group id="input-group-2" label="อีเมล"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="email" placeholder="กรอกอีเมล"
                                    required></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-6 col-xl-6"><b-form-group id="input-group-2" label="เบอร์โทรศัพท์"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="mobilePhone" placeholder="กรอกเบอร์โทรศัพท์"
                                    required></b-form-input>
                            </b-form-group></div>
                    </div>
                    <div class="submit-container">
                        <b-button variant="outline-black-100" v-on:click=(updateInspector())>บันทึก</b-button>
                    </div>
                </b-form>


            </b-card>
        </div>
    </b-row>
</template>

<script>
import { BRow, BCol, BCard, BButton, BForm, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BFormSelect, BFormInput, BAlert, BSpinner, BModal, BBreadcrumb } from "bootstrap-vue";
import { validEmail } from "../../../../../helpers/validation";
import PageTitle from "@/layouts/components/content/page-title/PageTitle.vue";
import inspectorService from "../../../../../services/inspector.service";

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormSelect,
        BFormInput,
        BAlert,
        BSpinner,
        BModal,
        BBreadcrumb,
        PageTitle
    },
    data() {
        return {
            inspectorId: null,
            firstname: null,
            lastname: null,
            email: null,
            mobilePhone: null,
            show: true,
            isLoading: false,
            errors: [],
            breadcrumbItems: [
                {
                    text: "การตั้งค่า",
                    href: "#",
                },
                {
                    text: "ผู้ทรงประจำกองบรรณาธิการ",
                    to: { name: "inspectors" },
                },
                {
                    text: "แก้ไขผู้ทรงประจำกองบรรณาธิการ",
                    active: true,
                },
            ],
        };
    },

    methods: {
        getInspector: async function () {
            this.inspectorId = this.$route.params.id;
            let response = await inspectorService.getInspectorById(this.inspectorId);
            let data = response.data.inspector;
            this.firstname = data.firstname;
            this.lastname = data.lastname;
            this.email = data.email;
            this.mobilePhone = data.phone;
        },

        updateInspector: async function () {
            try {
                this.isLoading = true;
                if (this.isValidate()) {
                    let newR = {
                        id: this.inspectorId,
                        firstname: this.firstname,
                        lastname: this.lastname,
                        email: this.email,
                        phone: this.mobilePhone,
                    }
                    await inspectorService.updateInspector(newR);
                    this.isLoading = false;
                    setTimeout(() => { this.isSaved = false; }, 3000)
                } else {
                    this.isLoading = false;
                    this.$bvModal
                        .msgBoxConfirm("กรุณาตรวจสอบข้อมูลอีกครั้ง", {
                            cancelVariant: "text",
                            okTitle: "ตกลง",
                            size: "sm",
                        })
                        .then((value) => {
                            this.boxOne = value;
                        })
                }


            } catch (error) {
                this.$bvModal
                    .msgBoxConfirm("กรุณาตรวจสอบข้อมูลอีกครั้ง", {
                        cancelVariant: "text",
                        okTitle: "ตกลง",
                        size: "sm",
                    })
                    .then((value) => {
                        this.boxOne = value;
                    })
            }


        },
        isValidate() {
            this.errors = [];
            if (!this.firstname) this.errors.push("Firstname Eng required.");
            if (!this.lastname) this.errors.push("Lastname Eng required.");
            if(!validEmail(this.email)) this.errors.push("Email required.");
            if (!this.mobilePhone) this.errors.push("Mobile Phone required.");
            if (!this.errors.length) return true;
        },

    },
    mounted() {
        this.getInspector();
    }

};
</script>