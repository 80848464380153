import { CONSTANTS } from "../helpers/variables";
import axiosService from "./axios.service";

class InspectorService {
    async getInspectors(keywords, currentPage, perPage, sortBy, sortDesc) {
        let keyword = keywords ? keywords : ""; 
        let inspectors = await axiosService.get(CONSTANTS.APIURL + `inspectors-list?keywords=${keyword}&page=${currentPage}&per_page=${perPage}&column=${sortBy}&orderby=${sortDesc}`);
        return inspectors;
    }
    async getInspectorById(id) {
        let inspectors = await axiosService.get(CONSTANTS.APIURL + `inspector?id=${id}` );
        return inspectors;
    }
    async addInspector(...inspector) {
        return await axiosService.post(CONSTANTS.APIURL + 'inspectors', ...inspector);
    }
    async updateInspector(...inspector) {
        return await axiosService.put(CONSTANTS.APIURL +  'inspectors', ...inspector);
    }
    async deleteInspector(id) {
        return await axiosService.detete(CONSTANTS.APIURL +`inspectors?id=${id}`);
    }
    async getInspector() {
        return await axiosService.get(CONSTANTS.APIURL +`inspectors`);
    }
}
export default new InspectorService();